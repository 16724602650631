import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

// core components
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";
import Header from "../../components/header/header.jsx";

// sections for this page
import { SECTION_INFOS } from "../../constants/section-infos.js";

const Components = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderBanner />
          {SECTION_INFOS.map(({ id, element }) => (
            <div key={id} id={id}>
              {element}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

Components.propTypes = {
  classes: PropTypes.object,
};

export default Components;
