import React, { useRef, useState, useEffect, Children } from "react";
import { motion } from "framer-motion/dist/framer-motion";

export const FadeInUp = ({ children }) => (
  <>
    {Children.map(children, (child) => (
      <Motion>{child}</Motion>
    ))}
  </>
);

const Motion = ({ children }) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold: 0.1 } // 뷰포트 내부의 10% 이상 보이면 요소가 뷰포트 내부에 있다고 판단
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 100 }}
      animate={isVisible ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
