import React from 'react';
import {
  Route,
  Switch
} from "react-router-dom";
import Components from "./views/components/components.jsx";

const App = () => (
  <Switch>
    <Route path="/" component={Components} />
  </Switch>
);

export default App;