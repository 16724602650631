/* eslint-disable */
import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { FadeInUp } from "../../../components/motion/fade-in-up";

const Partners = () => {
  return (
    <div className="testimonial3 spacer">
      <Container>
        <FadeInUp>
          <Row className="justify-content-center">
            <Col md="7" className="text-center m-b-40">
              <h1 className="title font-bold">PARTNERS</h1>
            </Col>
          </Row>
          <Row className="testi3 m-t-40 justify-content-center">
            <Col lg="4" md="12">
              <Card className="card-shadow">
                <CardBody>
                  <h4 className="font-bold m-b-30">TELPERS CO.</h4>
                  <h5 className="m-b-30">
                    Blockchain/Crypto <br></br>Localization Specialists
                  </h5>
                  <h6 className="font-light m-b-30">
                    24/7 365 Customer Support<br></br>On-Going Translation
                    Support
                    <br></br>Market Research & Reports
                  </h6>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="12">
              <Card className="card-shadow">
                <CardBody>
                  <h4 className="font-bold m-b-30">COINHUBKOREA CO.</h4>
                  <h5 className="m-b-30">
                    Korea Blockchain <br></br>& Crypto News Portal{" "}
                  </h5>
                  <h6 className="font-light m-b-30">
                    Media Relation & Articles Publication
                    <br></br>Market/Industry Headlines<br></br>
                    Public Awareness & Exposure
                  </h6>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="12">
              <Card className="card-shadow">
                <CardBody>
                  <h4 className="font-bold m-b-30">COZZUNO CO.</h4>
                  <h5 className="m-b-30">
                    Online Marketing <br></br>& Promotion Agency
                  </h5>
                  <h6 className="font-light m-b-30">
                    SNS Marketing (Blogging)<br></br>Influencer Relationship
                    <br></br>Event Hosting (Meetups, etc.)
                  </h6>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="testi3 m-t-20 justify-content-center">
            <Col lg="4" md="12">
              <Card className="card-shadow">
                <CardBody>
                  <h4 className="font-bold m-b-30">Dongin Law</h4>
                  <h5 className="m-b-30">
                    Legal Advisory<br></br>
                  </h5>
                  <h6 className="font-light m-b-30">
                  Crypto Expertise Advisory<br></br>Legal Translation Services
                    <br></br>Regulation Monitoring and Strategy
                  </h6>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="12">
              <Card className="card-shadow">
                <CardBody>
                  <h4 className="font-bold m-b-30">Korea Blockchain Cooperative</h4>
                  <h5 className="m-b-30">
                  First and only blockchain org<br></br>approved by the Korean Government
                  </h5>
                  <h6 className="font-light m-b-30">
                  Trading Seminars<br></br>Fintech Promotion 
                    <br></br>International Partnerships
                  </h6>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="12">
              <Card className="card-shadow">
                <CardBody>
                  <h4 className="font-bold m-b-30">The Vault</h4>
                  <h5 className="m-b-30">
                    Global Startup <br></br>Accelerator
                  </h5>
                  <h6 className="font-light m-b-30">
                    Venture Capital & Startup Advisory<br></br>
                    Full Stack Innovation Ecosystem<br></br> Co-working Space
                  </h6>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </FadeInUp>
      </Container>
    </div>
  );
};

export default Partners;
