/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Collapse, Container, Nav, NavItem, Navbar, NavbarToggler } from 'reactstrap';
import { SECTION_INFOS } from '../../constants/section-infos';

const Header = () => {
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(value => !value);

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW
    <NavbarBrand href="/"><img src={logo} alt="wrapkit" /></NavbarBrand>
    /*--------------------------------------------------------------------------------*/

    const handleNavItemClick = (id) => {
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            history.push(`#${id}`);
        }
    };

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                    <Navbar className="navbar-expand-lg h6-nav-bar">
                        <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                        <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 ml-auto" id="h6-info">
                            <Nav navbar className="ml-auto">
                                {SECTION_INFOS.map(({id, text}) =>
                                    <NavItem key={id} onClick={() => handleNavItemClick(id)}>
                                        <button className="nav-link">
                                            {text}
                                        </button>
                                    </NavItem>
                                )}
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );

}
export default Header;
