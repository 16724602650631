import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';
import BHTradingVertical from '../../assets/images/logos/bhtrading-vertical.png';

const HeaderBanner = () => {
    return (
        <div className="static-slider-head">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" md="6" className="align-self-center text-center">
                        <img src={BHTradingVertical} alt="logo" className="title" />
                        <h4 className="bannersubtitle font-light">Localization & Turn-Key Solutions for Global Crypto Projects</h4>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
