import React from 'react';
import Intro from "../views/components/sections/intro";
import Services from '../views/components/sections/services';
import Partners from '../views/components/sections/partners';

export const SECTION_INFOS = [
  {
    id: 'intro',
    text: 'INTRO',
    element: <Intro />,
  },
  {
    id: 'services',
    text: 'SERVICES',
    element: <Services />,
  },
  {
    id: 'partners',
    text: 'PARTNERS',
    element: <Partners />,
  },
];
