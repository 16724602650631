import React from "react";
import { Col, Container, Row } from "reactstrap";
import { FadeInUp } from "../../../components/motion/fade-in-up";

const Intro = () => {
  return (
    <div className="spacer" id="reactstrap-components">
      <Container>
        <Row className="justify-content-center">
          <Col md="10" className="text-center">
            <FadeInUp>
              <h1 className="title font-bold m-b-40">INTRO</h1>
              <h6 className="subtitle">
                <br></br>Since its establishment in 2019, BH Trading has been
                devoted to building a high-performing localization service team,
                providing full-scale strategic planning for blockchain startups,
                projects, and digital asset exchanges around the world for their
                strong market penetration and sustainable growth in South Korea.
                <br></br>
                <br></br>
                Our professionals are highly capable and well-experienced in
                providing the services aligned with the local market needs, from
                continuous language support to strong market exposure through
                communities and influencer networks. We will make your entrance
                into the this dynamic region exceptionally successful!
              </h6>
            </FadeInUp>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Intro;
