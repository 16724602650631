/* eslint-disable */
import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { FadeInUp } from "../../../components/motion/fade-in-up";

const Services = () => {
  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <FadeInUp>
            <Row className="justify-content-center">
              <Col md="10" className="text-center">
                <h1 className="title font-bold m-b-30">SERVICES</h1>
                <h6 className="subtitle m-b-40">
                  Day-to-Day Operation and Intensive Marketing & Promotion
                  through Different Channels and Networks
                </h6>
              </Col>
            </Row>
            <Row className="m-t-20">
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-address-book"></i>
                    </div>
                    <h5 className="font-medium">CUSTOMER SUPPORT</h5>
                    <p className="m-t-20">
                      Industry-leading experts providing 24/6 365 services
                      throughout multiple-channels.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-support"></i>
                    </div>
                    <h5 className="font-medium">TRANSLATION SUPPORT</h5>
                    <p className="m-t-20">
                      Accurate and real-time translations tailored to blockchain
                      & crypto projects.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="m-t-20">
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-search"></i>
                    </div>
                    <h5 className="font-medium">CHANNEL SUPPORT</h5>
                    <p className="m-t-20">
                      A wide range of the official project channels to enhance
                      user communications.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-bookmark"></i>
                    </div>
                    <h5 className="font-medium">MEDIA & COMMUNITY OUTREACH</h5>
                    <p className="m-t-20">
                      Multiple groups of online marketing & promotion channels
                      including major local media and online SNS/Influencers.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="m-t-20">
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-book"></i>
                    </div>
                    <h5 className="font-medium">
                      EDUCATION & CONTENTS DEVELOPMENT
                    </h5>
                    <p className="m-t-20">
                      A production of online tutorial series covering different
                      topics with other marketing contents related to the
                      project.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-space-shuttle"></i>
                    </div>
                    <h5 className="font-medium">
                      EVENT & NETWORKING MEETUP SPACE
                    </h5>
                    <p className="m-t-20">
                      Regular offline events, meetups and networking meetups at
                      team’s own space to enhance the market influence
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="m-t-20">
              <Col md="6" className="wrap-feature4-box">
                <Card>
                  <CardBody>
                    <div className="icon-round bg-light-info">
                      <i className="fa fa-connectdevelop"></i>
                    </div>
                    <h5 className="font-medium">
                      BLOCKCHAIN CROSS-BORDER DEALS
                    </h5>
                    <p className="m-t-20">
                      Resources and facilities to provide blockchain related
                      investment opportunities, from early stage seed
                      investments, cryptocurrency exchange M&As, and more.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </FadeInUp>
        </Container>
      </div>
    </div>
  );
};

export default Services;
